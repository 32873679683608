import React, { useEffect, useState, useContext } from "react";
import { AutoComplete } from 'primereact/autocomplete';
import fetch from '../../../config/service';
import config from "../../../config/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { GlobalContext } from "../../App/App";
const DoAutoCompleteField = (props) => {
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [isValidate, setIsValidate] = useState(false);
    const [noData, setNoData] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [disable, setDisable] = props.item.hasDependencyField ? useState(true) : useState(false);
    const context = useContext(GlobalContext);

    let message;
    const id = props.id ? props.id : props.name;
    const label = props.label ? props.label : props.name;
    const name = props.name;
    const type = props.type ? props.type : 'text';
    const field = props.field ? props.field : {};
    const placeholder = props.placeholder;
    const item = props.item ? props.item : {};
    let parentField = props.watch && props.item.hasDependencyField ? props.watch(props.item.hasDependencyField) : '';

    useEffect(() => {
        setDisable(props.disabled || props?.item?.disabled ? true : false)
    }, [props.disabled, props?.item?.disabled]);

    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem('defaultProvider'));
        if (props?.formType === 'add' && item?.name === 'provider' && !props?.treatmentPlanAutocomplete) {
            setTimeout(() => { onSelect({ value: userData }) }, 500);
        }
    }, [localStorage.getItem('defaultProvider')]);

    useEffect(() => {
        const clinicData = context?.clinicData;
        if (props.setValue && props?.formType !== 'edit') {
            props.setValue('clinicId', clinicData);
        }
        if (props.formType === 'add' && item.name === 'clinicId') {
            setDisable(clinicData ? true : false);
            setTimeout(() => { onSelect({ value: clinicData }) }, 100)
        }
    }, [context?.clinicData]);

    useEffect(() => {
        const patientData = context?.patientData;
        if (props.formType === "add" && props.name === 'patientId') {
            setDisable(patientData ? true : false);
            setTimeout(() => { onSelect({ value: patientData }) }, 120)
        }
    }, [context?.patientData]);

    useEffect(() => {
        if (item.hasDependencyField) {
            if (parentField) {
                // setDisable(false);
                setErrorMessage('');
            } else {
                // setDisable(true);
            }
            field.onChange(undefined);
        }
    }, [parentField])
    
    const onSelectRecord = (e) => {
        setNoData(false);
        setIsValidate(false);
        setErrorMessage('');
        if (props.input) {
            const { name, onChange } = this.props.input;
            onChange(e.value);
            this.props.handleAutoCompleteData(e.value, name)
            if (e && e.value) {
                this.removeDuplicates(e.value, name);
            }
        }

    }


    const clearData = () => {//when we need to clear the data on the field
        if (!disable) {
            onSelect({ value: undefined });
            setDisable(false);
        }
    }

    const getAllSuggestions = (event, type) => {
        let url;
        setIsValidate(false);
        setNoData(false);
        setErrorMessage('');
        let filterCriteria = {
            limit: 30,
            // page: 1,
            sortfield: "created",
            direction: "desc",
            criteria: []
        };

        if (item.clinicFilter && context && context.clinicData) {
            filterCriteria.criteria = [{ key: "clinicId", value: context.clinicData._id, type: "eq" }]
        }

        if (item.providerFilter && context && context.clinicData) {
            filterCriteria.criteria = [{ key: "clinicsId", value: [context.clinicData._id], type: "in" }]
        }

        if (item.hasDependencyField) {
            if (props.getValues(props.item.hasDependencyField) && props.getValues(item.hasDependencyField)["_id"]) {
                filterCriteria['criteria'].push({
                    "key": item.fieldForKey,
                    "value": props.getValues(item.hasDependencyField)["_id"],
                    "type": item.hasDependencyFieldType || "eq",
                })
            } else {
                setNoData(true);
                setFilteredSuggestions([]);
                setErrorMessage('Please select the ' + item.hasDependencyField[0].toUpperCase() + item.hasDependencyField.slice(1));
                return;
            }
        }
        if (item.defaultFilterCriteria && item.defaultFilterCriteria.length > 0) {
            filterCriteria['criteria'] = [...filterCriteria['criteria'], ...item.defaultFilterCriteria];
        }

        if (event && event.query) {
            if (item.searchPatternMatch && !item.searchPatternMatch?.test(event.query)) {//searchPatternMatch it should Regular expresetion
                setNoData(true);
                setFilteredSuggestions([]);
                return;
            }

            if (item?.searchGlobally) {
                filterCriteria["globalSearch"] = { "value": event.query, "type": "user" };
            } else {
                filterCriteria['criteria'].push({
                    "key": props.searchField,
                    "value": event.query,
                    "type": "regexOr"
                });
            }
        };

        let apiUrl = props.searchApi?.toLowerCase();
        url = `${apiUrl}?searchFrom=autoComplete&filter=${JSON.stringify(filterCriteria)}`;
        return fetch('GET', url)
            .then((response) => {
                if (response) {
                    let dropdownData = [];
                    if (response[apiUrl] && response[apiUrl].length && response[apiUrl].length > 0) {
                        dropdownData = response[apiUrl];
                    }
                    if (!item.disableSelectFirstOption && response[apiUrl] && response[apiUrl].length === 1 && !item.isMultiple && field?.value?._id !== response[apiUrl][0]._id) {
                        // props.setValue(props.field.name, response[apiUrl][0]);
                        onSelect({ value: response[apiUrl][0] });
                        // setTimeout(() => {
                        //     const elements = document.getElementsByClassName('p-autocomplete-panel');
                        //     for (let i = 0; i < elements.length; i++) {
                        //         elements[i].style.display = 'none';
                        //     }
                        // },);
                    }
                    if (dropdownData && dropdownData.length == 0) {
                        setFilteredSuggestions([]);
                        setNoData(true);
                    } else {
                        setSuggestions(dropdownData);
                    }
                }
            }).catch((err) => {
                return err;
            });
    };

    const setSuggestions = async (dropdownData) => {
        if (props.input && props.input.value) {
            if (props.input.value.length > 0) {
                let values = props.input.value;
                if (Array.isArray(values)) {
                    values.forEach(element => {
                        let field = '_id';
                        dropdownData = dropdownData.filter((item) => item[field] !== element[field]);
                    });
                }
            }
        }
        setFilteredSuggestions(dropdownData);
    }

    if (props.fieldState.invalid) {
        message = props.errors[props.name]?.message;
        if (props.errors[props.name]?.message.includes("must be a `object` type")) {
            message = "Select a valid option";
        }
    }

    let markRequired = () => {
        return (
            <FontAwesomeIcon
                color='red'
                icon={faAsterisk}
                style={{ width: '7px', marginBottom: "5px", marginLeft: "2px" }}
            />
        );
    };

    function onSelect(e) {
        if (!item.isMultiple) {
            field.onChange(e.value);
        }

        // if (!props.getValues(item.name)) {
        //     props.setValue(item.name, e.value)
        // }

        if (props.onChange) {
            props.onChange(e.value, props.field.name, props);
        }
        if (item.onChange) {
            item.onChange(e.value, props.setValue);
        }
    }

    return (
        <div className="flex flex-column">

            {!item.disableLabel && <div className="doautocomplete-label flex align-center"> {/* Added a wrapper div with flex class */}
                <label htmlFor={id} className="text-capitalize">{label}</label>
                {props.markReq && props.markReq === true && markRequired()}
            </div>}
            <div className="d-flex align-items-center">
                <AutoComplete
                    className="w-100"
                    inputId={field.name}
                    value={field.value}
                    onChange={(e) => field.onChange(e.value ? e.value : undefined)}
                    // inputRef={field.ref}
                    panelStyle={{ width: '100px' }}
                    disabled={disable}
                    suggestions={filteredSuggestions}
                    completeMethod={getAllSuggestions}
                    placeholder={field.value == undefined || field.value.length == 0 ? placeholder : ''}
                    field={props.searchField}
                    dropdown={true}
                    onSelect={onSelect}
                    multiple={props.multiple}
                    selectionLimit={props.selectionLimit ? props.selectionLimit : config.selectionLimit}
                    style={item.style ? item.style : {}}
                    showEmptyMessage={true}
                    emptyMessage='No Data Found'
                    itemTemplate={item.itemTemplate}
                />
                {item.clearable && field.value && <FontAwesomeIcon
                    className=''
                    color={field.value ? 'red' : 'grey'}
                    icon={faTimes}
                    size='lg'
                    data-toggle="tool-tip"
                    onClick={clearData}
                    style={{ width: '1.5rem' }}
                />}
            </div>
            <small className="text-danger ">{props.fieldState?.invalid ? message ? message : props.errors[props.name]?.message : ''}</small>
            {errorMessage && <small className="text-danger">{errorMessage}</small>}
        </div>
    )

}

export default DoAutoCompleteField;
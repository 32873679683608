import React, { useEffect, useState, useContext, useRef } from "react";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import fetchMethodRequest from "../../../../config/service";
import TreatmentPlanInputField from "../../Prescriptions/components/TreatmentPlanInputField";
import DragAndDropTreatment from "../../../Form/Fields/DragAndDropTreatment";
import { GlobalContext } from "../../../App/App";

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// config file
const TreatmentPlan = (props) => {
    const [customFieldValidation, setCustomFieldValidation] = useState({ save: true });
    const [schema, setSchema] = useState('');
    const [patientData, setPatientData] = useState();
    const treatmentPlanInputFieldRef = useRef();


    let {
        handleSubmit,
        register,
        reset,
        setValue,
        getValues,
        setError,
        clearErrors,
        formState: { errors },
        control,
        watch
    } = useForm({
        // resolver: yupResolver(schema),
    });


    const item = {
        name: "treatmentPlanHeading",
        type: "customType",
        // customType: treatementCustomType,
        placeholder: "Select a Treatment Plan",
        label: "Treatment",
        disableLabel: true,
        derivedValue: "heading=undefined",
        id: "treatmentPlanHeading",
        searchApi: apiCalls.treatmentPlan,
        searchField: 'heading',
        hasDependency: "true",
        fieldForKey: "patientId",
        disableSelectFirstOption: true,
        // defaultFilterCriteria: [{ key: 'proceduresStatus', value: true, type: 'eq' }], //For active filters 
        hasDependencyField: "patientId",
        patientFilter: true,
    };

    const customProps = { i: 1, item, handleSubmit, register, reset, setValue, getValues, control, watch, Controller, setCustomFieldValidation, setError, clearErrors, errors };

    const context = useContext(GlobalContext);

    useEffect(() => {
        if (localStorage.PatientData) {
            setTimeout(() => {
                setPatientData(JSON.parse(localStorage.PatientData));
            }, 100);
        } else {
            showToasterMessage('Please select a patient.', 'warning');
        }
    }, []);

    useEffect(() => {
        setValue('patientId', patientData);
        if (treatmentPlanInputFieldRef?.current && patientData) {

            treatmentPlanInputFieldRef?.current?.fetchTreatmentPlans().then(res => {
                if (res && Array.isArray(res) && res.length > 0) {
                    setValue(item.name, res[0]);
                } else {
                    treatmentPlanInputFieldRef.current?.addTreatmentPlan();
                }
            });
        }
    }, [patientData]);

    useEffect(() => {
        setPatientData(context?.patientData);
    }, [context?.patientData]);


    const submit = (values) => {
        if (customFieldValidation.save) {
            const treatmentPlanHeading = values.treatmentPlanHeading;
            const url = `${apiCalls.treatmentPlan}/${treatmentPlanHeading._id}`;
            values._id = treatmentPlanHeading._id;
            fetchMethodRequest('PUT', url, values).then(res => {
                if (res.respCode) {
                    setValue('treatmentPlanHeading', res.details)
                } else {

                }
            });
        }
    }

    return <div className="p-2 bg-white">
        <form onSubmit={handleSubmit(submit)} autoComplete={'off'}>
            <div className="p-2 form_form-header">
                <h4 style={{ textTransform: 'capitalize' }}><b>
                    <div className='text-white'>Treatment Plan</div>
                </b> </h4>
            </div>
            <div className="row form p-2">

                <div className="col-12">
                    <DragAndDropTreatment
                        {...customProps}
                        data={watch('treatmentPlanHeading') ? watch('treatmentPlanHeading') : ''}
                        patient={patientData}
                    >
                        <TreatmentPlanInputField {...customProps} ref={treatmentPlanInputFieldRef} />
                    </DragAndDropTreatment>
                </div>
            </div>
        </form>
    </div>
};

export default TreatmentPlan;
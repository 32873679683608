const apiCalls = {
  activities: "activities",
  loginUser: "auth/login",
  logoutUser: "auth/logout",
  loginChangePassword: "auth/loginChangePassword",
  MenuList: "menus",
  Settings: "settings",
  LocationImagePath: "uploads?uploadWhileCreate=true&uploadPath=location",
  ProfileImagePath: "uploads?uploadWhileCreate=true&uploadPath=employee",
  dashboard: "dashboard/counts",
  Uploads: "bulkuploadStatus",
  prescriptionUpload: 'uploads?uploadPath=prescription',
  employee: "employees",
  templates: "templates",
  employees: "employees",
  hospitals: "hospitals",
  patients: "patients",
  users: "users",
  forms: "forms",
  appointments: "appointments",
  appointmentsUncheckout:"appointments/unCheckOutAppointment",
  questions: "questions",
  slots: "slots",
  treatments: "treatments",
  slotupdate: "appointments/newslotupdate",
  slotcancel: "appointments/slotcancel",
  procedures: 'procedures',
  treatmentPlan: 'treatmentplan',
  txPlan: 'txPlan',
  dragAndDrop: 'appointments/dragAndDrop',
  resizing: 'appointments/resizing',
  multipleForms: 'forms/createMultipleForms',
  schedules: 'schedules',
  drugs: "drugs",
  Insurence: "insurance",
  benifits: 'benifits',
  pharmacies: "pharmacies",
  clinics: 'clinics',
  prescriptions: 'prescriptions',
  chairs: "chairs",
  txPlans: "txplans",
  treatmentPlans: "treatmentPlans",
  reasons: "reasons",
  insurances: "insurances",
  carrier: 'carriers',
  feeSchedules: 'feeschedules',
  generateReports: "reports/generateReports",
  sendEmailReports: "reports/sendEmail",
  reports: "reports/getReports",
  releaseNotes: 'releaseNotes',
  teethImages: 'teethImages',
  getImagesByProcedure: 'teethImages/getimages',
  getTeethImages: 'teethimages',
  clockInClockOut: 'clockInAndClockOuts',
  availablePrompts: 'availablePrompts',
  autoNotes: 'autoNotes',
  chartHistories: 'chartHistories',
  patientNotes: 'patientNotes',
  availablePrompts: 'availablePrompts',
  autoNotes: 'autonotes',
  chartHistories: 'chartHistories',
  accounts: 'accounts',
  accountsAdjustment: 'accounts/adjustmentAddSub',
  accountsStatement: 'accounts/statement',
  makePayments: 'makePayments',
  createPayment: 'payments/createPayment',
  requestPayment: 'payments/requestPayment',
  cardknoxPayment: 'payments/createCardKnoxPayment',
  referrals: 'referrals',
  adjustmentAddSub: 'accounts/adjustmentAddSub',
  duesAndSubscriptions: "duesAndSubscriptions",
  paidHistories: "paidHistories",
  purchaseHistories: "purchaseHistories",
  inventories: "purchaseHistories/inventory",
  signTP: 'treatmentplan/signTP',
  imageMaintaining: 'imageMaintaining',
  claimManagements: 'claimManagements',
  sendEmailtoPatient: 'accounts/sendEmailtoPatient',
  dashboard: "dashboard",
  upcomingAppointments: "dashboard/upcomingAppointments",
  clinicWiseGraph: "dashboard/clinicWiseGraph",
  perioCharts: "periocharts",
  patientScreens: "patientscreens",
  getCart: "purchaseHistories/getCart",
  addToCart: "purchaseHistories/addToCart",
  getCartCounts: "purchaseHistories/getCartCounts",
  deleteCart: "purchaseHistories/deleteCart",
  openai: "openai/extractFormValues"
};

// {"loginUser":"auth/login","logoutUser":"auth/logout","loginChangePassword":"auth/loginChangePassword","MenuList":"menus","Settings":"settings","LocationImagePath":"uploads?uploadWhileCreate=true&uploadPath=location","ProfileImagePath":"uploads?uploadWhileCreate=true&uploadPath=employee","dashboard":"dashboard/counts","employee":"employees","employees":"employees","Uploads":"bulkuploadStatus"}

export default apiCalls;

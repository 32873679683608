import { useState, useEffect } from "react";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";

/**@CustomHook */
const useSpeechToText = (options) => {
  const [isListening, setIsListening] = useState(false);
  const [transcript, setTranscript] = useState("");


  const { transcript: recognitionTranscript, resetTranscript: recognitionReset } = useSpeechRecognition({
    commands: options?.commands || [],
    isFuzzyMatch: true,
    matchInterim: true,
    fuzzyMatchingThreshold: 0.7 
  });

  /**@UseEffects (LifeCycle) */
  useEffect(() => {
    setTranscript(recognitionTranscript);
  }, [recognitionTranscript]);



  /**@Functions */
  const startListening = () => {
    if (!isListening) {
      SpeechRecognition.startListening({
        continuous: options?.continuous || false,
        language: options?.lang || "en-US",
      });
      setIsListening(true);
    }
  };

  const stopListening = () => {
    if (isListening) {
      SpeechRecognition.stopListening();
      setIsListening(false);
    }
  };

  const resetTranscript = () => {
    recognitionReset();
    setTranscript("");
  };

  // Function to manually update the transcript
  const updateTranscript = (newTranscript) => {
    setTranscript(newTranscript);
  };

  return {
    isListening,
    transcript,
    resetTranscript,
    startListening,
    stopListening,
    updateTranscript, // Expose the function to manually update the transcript
  };
};

export default useSpeechToText;


import { useRef, forwardRef, useImperativeHandle, useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";

/**@Icons */
import CloseIcon from "mdi-react/CloseIcon";
import MicroPhone from 'mdi-react/MicrophoneIcon';

/**@Custom */
import CustomModal from '../../containers/Cruds/CommonModals/CustomModal';
import useSpeechToText from '../../hooks/useSpeechToText';
import DoEditor from '../../containers/Form/Fields/DoEditor';
import fetchMethodRequest from '../../config/service';
import apiCalls from '../../config/apiCalls';
import EventEmitter from '../../events/EventEmitter';
import BLoader from '../Loader/BeatLoader';


/**@DefaultProps */
const defaultProps = {
    children: null,
    showLoader: true,
    modalWidth: "80vw",
    modalPosition: "top",
    modalHeight: null,
    type: null,
    commands: [],
    formFields: [],
    setListen: () => {},
    setTranscript: () => {}
}

/**@PropTypes */
const voiceProcessPropTypes = {
    children: PropTypes.node,
    modalWidth: PropTypes.string,
    modalPosition: PropTypes.string,
    modalHeight: PropTypes.string,
    type: PropTypes.string,
    commands: PropTypes.array,
    formFields: PropTypes.array,
    setListen: PropTypes.func,
    setTranscript: PropTypes.func,

}


/**@Component */
const VoiceProcess = forwardRef(({ children, modalWidth, modalPosition, modalHeight, type, commands, formFields, setListen, setTranscript, showLoader }, ref) => {
    const [isLoading, setIsLoading] = useState(false);
    const { control, handleSubmit, reset, formState: { errors } } = useForm();

    /**@CustomHooks */
    const { isListening, transcript, updateTranscript, resetTranscript, startListening, stopListening } = useSpeechToText({
        continuous: true,
        lang: "en-US",
        commands
    });

    /**@UseEffects (LifeCycles) */

    useEffect(() => {
        resetTranscript("");
        reset({ transcript: "" });
    }, []);


    useEffect(() => {
        setListen(isListening);
    }, [isListening]);

    useEffect(() => {
        if(setTranscript) setTranscript(transcript)
    },[transcript])


    /**@functions */

    const startStopListening = () => {
        if (isListening) {
            stopListening();
        } else {
            startListening();
        }
    };

    /**
     * @description callback function to add the custom actions
     * @returns microphone icon
     */
    const customActions = () => {
        return (
            <div className="ms-2" onClick={startStopListening}>
                <div className="d-flex justify-content-center">
                    <MicroPhone color={"#ffff"} size={24} />
                </div>
            </div>
        );
    };

    /**
     * @description form submitter
     * @param {*} data -  transcript
     */
    const submit = (data) => {
        setIsLoading(true);
        const body = { ...data, formFields };
        fetchMethodRequest("POST", apiCalls.openai, body)
            .then((response) => {
                // EventEmitter.emit("openFormModal", response);
                EventEmitter.emit("setDefaultValues", response);
            })
            .finally(() => {
                setIsLoading(false);
            });

        startStopListening();
        reset({ transcript: "" });
        resetTranscript("");
    };

    useImperativeHandle(ref, () => ({
        startStopListening,
        resetTranscript,
        stopListening,
        transcript,
        isListening,
        customActions,
    }));

    return (
        <div className="">
            { showLoader && <BLoader isLoading={isLoading} /> }
            <CustomModal visible={isListening} onHide={startStopListening} width={modalWidth} position={modalPosition} height={modalHeight}>
                {children ? (
                    children
                ) : (
                    <div className="p-4">
                        <div className="d-flex align-items-center w-100">
                            <CloseIcon className="ms-auto mb-3 text-danger" size={24} onClick={startStopListening} />
                        </div>
                        <form onSubmit={handleSubmit(submit)}>
                            <Controller
                                name="transcript"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <DoEditor
                                        field={{
                                            ...field,
                                            value: transcript, // Sync transcript with editor value
                                            onChange: (value) => {
                                                field.onChange(value);
                                                updateTranscript(value);
                                                // setTranscript(value); // Update transcript state
                                            },
                                        }}
                                        fieldState={fieldState}
                                        errors={errors}
                                    />
                                )}
                            />
                            <div className="d-flex w-100 mt-4 mb-2">
                                <button className="ms-auto px-4 py-2 rounded border-0 bg-primary text-white">Send</button>
                            </div>
                        </form>
                    </div>
                )}
            </CustomModal>
        </div>
    );
});


VoiceProcess.propTypes = voiceProcessPropTypes;
VoiceProcess.defaultProps =  defaultProps

export default VoiceProcess;

import comparsion from "string-similarity";
import apiCalls from "../config/apiCalls";
import fetchMethodRequest from "../config/service";


/**
 * 
 * @param {*} navigate - react router dom
 * @param {*} done - close speach modal
 * @returns 
 */
export const getVoiceCommands = async (navigate, done, retry) => {
    const menulists = await fetchMethodRequest("GET", apiCalls.MenuList);
    const commands = [
      {
        command: "Open * form",
        callback: page => {
          const lowerPage = page.trim().toLowerCase();
          const found = findMatch(lowerPage, menulists.menulists, navigate, () => done("openForm"));
          if (!found) retry();
        }
      },
      {
        command: "Go to *",
        callback: (page) => {
          const lowerPage = page.trim().toLowerCase();
          const found = findMatch(lowerPage, menulists.menulists, navigate, done);
          if (!found) retry();
        },
      },
      {
        command: "Open *",
        callback: (page) => {
          const lowerPage = page.trim().toLowerCase();
          const found = findMatch(lowerPage, menulists.menulists, navigate, done);
          if (!found) retry();
        },
      },
      {
        command: "reset",
        callback: () => alert("Resetting!"),
      },
    ];
    return commands;
  };
  

  /**
   * @description - recursive function to handle the route matching
   * @param {*} page 
   * @param {*} menulists 
   * @param {*} navigate 
   * @param {*} done 
   * @returns 
   */
  const findMatch = (page, menulists, navigate, done) => {
    for (const menu of menulists) {
      if (menu.submenus?.length > 0) {
        const isMatched = findMatch(page, menu.submenus, navigate, done);
        if (isMatched) return true;
      } 
      else if (page === menu?.displayTitle?.toLowerCase()?.trim()) {
        navigate(menu.route);
        done();
        return true;
      }
      else if(comparsion.compareTwoStrings(page, menu?.displayTitle?.toLowerCase()?.trim()) >= 0.5) {
        navigate(menu.route);
        done();
        return true;
      }
    }
    return false;
  };
  
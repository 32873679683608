import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';

export default function LineGraph({ graphData={}, clinics=[] }) {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color') || '#333'; // Fallback to black if the variable is missing
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary') || '#666';
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border') || '#ccc';

        const graphColors = ["#38bdf8", "#ec4899","#818cf8", "#14b8a6", "#fb923c", "#64748b", "#a3e635", "#4d7c0f", "#0369a1", "#7e22ce", "#38bdf8", "#ec4899","#818cf8", "#14b8a6", "#fb923c", "#64748b", "#a3e635", "#4d7c0f", "#0369a1", "#7e22ce", "#38bdf8", "#ec4899","#818cf8", "#14b8a6", "#fb923c", "#64748b", "#a3e635", "#4d7c0f", "#0369a1", "#7e22ce"]
        let datasets =  clinics.map((clinic, index) =>  {
            return {
                label: clinic.clinicName, 
                data: Object.keys(graphData).map(day => {
                    return graphData[day].find(result => result.clinicId == clinic.clinicId).count;
                }),
                fill: false,
                border: graphColors[index],
                borderColor: graphColors[index],
                tension: 0.4
            }
        })
        const data = {
            labels: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thrusday', 'Friday', 'Saturday'],
            datasets
        };

        const options = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    labels: {
                        color: textColor,
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary,
                    },
                    grid: {
                        color: surfaceBorder,
                    },
                },
                y: {
                    ticks: {
                        color: textColorSecondary,
                        stepSize: 5
                    },
                    grid: {
                        color: surfaceBorder,
                    },
                    min: 0,
                },
            },
        };

        setChartData(data);
        setChartOptions(options);
    }, [graphData, clinics]);

    return (
        <div className="w-100 px-2 py-1">
            <Chart type="line" data={chartData} options={chartOptions} height='530px' />
        </div>
    );
}

import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog } from 'primereact/dialog';
import * as yup from "yup";
import { Button } from 'reactstrap';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import DoAutoCompleteField from '../../Form/Fields/DoAutoCompleteField';
import config from '../../../config/config';
import DoInputField from '../../Form/Fields/DoInputField';
import DoSelectField from '../../Form/Fields/DoSelectField';
import DoDateField from '../../Form/Fields/DoDateField';
import Signature from '../../Form/Fields/Signature';
import DoTextareaField from '../../Form/Fields/DoTextareaField';
import apiCalls from '../../../config/apiCalls';

const TreatmentPlanDialog = ({ visible, onHide, selectedRowData, onSave }) => {

    const statusOptions = [
        { label: 'Treatment Planned', value: 'Treatment Planned' },
        { label: 'Complete', value: 'Complete' },
        { label: 'Existing-Current Prov', value: 'Existing-Current Prov' },
        { label: 'Existing-other Prov', value: 'Existing-other Prov' },
        { label: 'Referred Out', value: 'Referred Out' },
        { label: 'Condition', value: 'Condition' },
    ];

    const formFields = [
        { name: 'dateTp', type: 'date', show: true, label: 'Date TP', placeholder: "Date TP", id: "dateTp", width: "130px", required: true, disabled: true, dateFormat: "MM-DD-YYYY", fieldType: "Date", },
        { name: 'code', type: 'text', show: true, label: 'Code', placeholder: "Code", id: "code", width: "130px", required: true, disabled: true },
        { name: 'description', type: 'text', show: true, label: 'Description', placeholder: "Description", id: "description", width: "130px", required: true, disabled: true },
        { name: 'teethNumber', type: 'dropdown', options: config.teeths, show: true, label: 'TTH', placeholder: "TTH", id: "teethNumber", width: "130px", required: false, disabled: selectedRowData?.treatmentStatus == "Complete" },
        { name: 'fee', type: 'text', show: true, label: 'Fee', placeholder: "Fee", id: "fee", width: "130px", required: true, disabled: true },
        { name: 'priority', type: 'dropdown', options: config.priorityOptions, show: true, label: 'Priority', placeholder: "Priority", id: "priority", width: "130px", required: false, disabled: selectedRowData?.treatmentStatus == "Complete" },
        { name: 'treatmentStatus', type: 'dropdown', options: statusOptions, show: true, label: 'Procedure Status', placeholder: "Procedure Status", id: "treatmentStatus", width: "130px", required: true, disabled: selectedRowData?.treatmentStatus == "Complete" },
        {
            name: 'provider', type: 'relateAutocomplete', show: true, placeholder: "Provider", id: "provider", label: 'Provider', width: "130px", searchApi: "users", searchField: "name", required: true, disabled: false,
            defaultFilterCriteria: [{ "key": 'role', "value": config.doctorRole, "type": "regexOr" }], providerFilter: true,
        },
        { name: 'referral', type: 'relateAutocomplete', show: true, label: 'Referral', placeholder: "Referral", id: "referral", width: "130px", required: false, disabled: false,searchApi: apiCalls.referrals, searchField: "name", },
        { name: 'signature', type: 'signature', show: true, label: 'Signature', placeholder: "Sser", id: "signature", width: "130px", required: false, disabled: false },

        { name: 'autoNote', type: 'textarea', show: true, label: 'Notes', placeholder: "Notes", id: "autoNote", width: "130px", required: false, disabled: false, customWidthClass: 'col-12', rows: 5 },

    ]

    const [schema, setSchema] = useState('');


    const {
        handleSubmit,
        register,
        reset,
        setValue,
        formState: { errors },
        control,
    } = useForm(
        {
            resolver: yupResolver(schema)
        }
    );

    useEffect(() => {
        const schema = setValidations(formFields);
        setSchema(schema);
    }, []);

    useEffect(() => {
        if (selectedRowData) {
            reset({
                description: selectedRowData.description || '',
                treatmentStatus: selectedRowData.treatmentStatus || 'Treatment Planned',
                dateTp: new Date(selectedRowData.created) || '',
                note: selectedRowData.note || '',
                teethNumber: selectedRowData.teethNumber || null,
                priority: selectedRowData.priority || null,
                code: selectedRowData.code || '',
                fee: selectedRowData.fee || '',
                provider: selectedRowData.provider || selectedRowData?.patientId?.provider,
                referral: selectedRowData?.referral ,
                autoNote: selectedRowData.autoNote || '',
                signature: selectedRowData.signature || '',
            });
        } else {
            // If there is no selectedRowData, set default values including default for treatmentStatus
            reset({
                treatmentStatus: 'Treatment Planned', // Default value when there is no selectedRowData
            });
        }
    }, [selectedRowData, reset]);

    let getValidations = (field) => {
        if (field.type === 'number') {
            return yup.number().required(`${field.label} is a required field`);
        } else if (field.type === 'relateAutocomplete') {
            return yup.object().required(`${field.label} is a required field`);
        } else {
            return yup.string().required(`${field.label} is a required field`);
        }
    }

    const setValidations = (fields) => {
        let valObj = {};
        fields.forEach((field) => {
            if (field.required && field.show) {
                valObj[field.name] = getValidations(field);
            }
        });

        return yup.object().shape(valObj)
    }


    const onSubmit = (data) => {
        onSave(data);
        // onHide();
    };

    const dialogFooter = (
        <div className="p-d-flex p-jc-end">
            <Button color="primary" onClick={onHide}>Cancel</Button>
            <Button color="primary" onClick={handleSubmit(onSubmit)}>Update</Button>
        </div>
    );

    let getAutoComplete = (i, item) => {
        const itemTemplate = (option) => {
            const displayText = item.showField
                ? `${option[item.searchField]} (Name: ${option[item.showField]})`
                : option[item.searchField];

            return (
                <div className="autocomplete-item">
                    <div>{displayText}</div>
                </div>
            );
        };

        return (
            <div className='col-md-6 p-1'>
                <Controller
                    name={item.name}
                    rules={{ required: `${item.label} is required` }}
                    control={control}
                    render={({ field, fieldState }) => (
                        <>
                            <DoAutoCompleteField
                                markReq={item.required}
                                input={field}
                                id={field.id}
                                name={field.name}
                                field={field}
                                filterField={item.filterField}
                                filterValue={item.filterValue}
                                filterType={item.isNotEq}
                                multiple={item.isMultiple}
                                fieldState={fieldState}
                                errors={errors}
                                screen={'Users'}
                                searchApi={item.searchApi}
                                itemTemplate={itemTemplate}
                                searchField={item.searchField}
                                // allow={props.allowDuplicates}
                                filterFieldType={item.filterFieldType ? item.filterFieldType : null}
                                placeholder={item.placeholder}
                                label={item.label}
                                item={item}
                                formType={"add"}
                                disabled={item.disabled}
                                populateValue={item.searchField}
                                populateField={item.showField}
                                providerFilter={item.providerFilter && item.providerFilter}
                                setValue={setValue}
                                treatmentPlanAutocomplete={"true"}
                            />
                        </>
                    )}
                />
            </div>
        )
    }

    let getDate = (i, item) => {

        return (

            <div className='col-md-6 p-1'>
                <Controller
                    name={item.name}
                    control={control}
                    render={({ field, fieldState }) => (
                        <DoDateField
                            markReq={item.required}
                            input={field}
                            item={item}
                            label={item.label}
                            id={field.id}
                            name={field.name}
                            field={field}
                            fieldState={fieldState}
                            errors={errors}
                            placeholder={item.placeholder ? item.placeholder : "date"}
                        //   formType={props.formType}
                        // onChange={onChange} 
                        />)}
                />
            </div>
        )
    }

    let getDropdown = (i, item) => {
        return (
            <div className={`${item.customWidthClass ? item.customWidthClass : `col-md-6 `} p-1`}>
                <Controller
                    name={item.name}
                    control={control}
                    render={({ field, fieldState }) => (
                        <DoSelectField
                            markReq={item.required}
                            input={field}
                            id={field.id}
                            item={item}
                            name={field.name}
                            field={field}
                            label={item.label}
                            fieldState={fieldState}
                            errors={errors}
                            options={item.options}
                            optionLabel={item.optionLabel ? item.optionLabel : 'label'}
                            placeholder={item.placeholder}
                        // onChange={e => handleFnEnableControlsBasedOnValue2(e, item.dependent)}
                        />)}
                />
            </div>
        )
    }

    let getDefault = (i, item) => {
        return (
            <div className={`${item.customWidthClass ? item.customWidthClass : `col-md-6 `} p-1`}>
                <Controller
                    name={item.name ? item.name : null}
                    control={control}
                    render={({ field, fieldState }) => (
                        <DoInputField
                            markReq={item.required}
                            input={field}
                            item={item}
                            id={field.id}
                            name={field.name}
                            label={item.label}
                            field={field}
                            type={item.type ? item.type : "text"}
                            fieldState={fieldState}
                            errors={errors}
                            placeholder={item.placeholder ? item.placeholder : null}
                        />)}
                />
            </div>
        )
    }
    const getSignature = (index, item) => {

        return (
            <div className={`${item.customWidthClass ? item.customWidthClass : `col-md-6 `} p-1`}>

                <Controller
                    name={item.name ? item.name : null}
                    control={control}
                    render={({ field, fieldState }) => (
                        <Signature
                            item={item}
                            field={field}
                            fieldState={fieldState}
                            errors={errors}
                        />)}
                />
            </div>
        )
    }

    let getTextArea = (i, item) => {
        return (
            <div className={`${item.customWidthClass ? item.customWidthClass : `col-md-6`} p-1`}>
                <Controller
                    name={item.name ? item.name : null}
                    control={control}
                    render={({ field, fieldState }) => (
                        <DoTextareaField
                            markReq={item.required}
                            input={field}
                            id={field.id}
                            label={item.label}
                            item={item}
                            rows={item.rows}
                            name={field.name}
                            field={field}
                            fieldState={fieldState}
                            errors={errors}
                            placeholder={item.placeholder ? item.placeholder : null}
                        />)}
                />
            </div>
        )

    }



    // get Individual form field to display
    const getField = (item, i) => {

        return item.type === "relateAutocomplete" ? getAutoComplete(i, item)
            : item.type === "date" ? getDate(i, item)
                : item.type === "dropdown" ? getDropdown(i, item)
                    : item.type === "signature" ? getSignature(i, item)
                        : item.type === "textarea" ? getTextArea(i, item)
                            : item.type === "text" ? getDefault(i, item) : null
    }

    // get form fields
    const getFields = () => {
        return (<div>
            <div className=''>
                <div className='form  mb-2 mt-4'>
                    {formFields && formFields.map(getField)}
                </div>
            </div>
        </div>)
    }

    return (
        <>
            <Dialog
                header="Edit Procedure"
                visible={visible}
                headerClassName="modal-header"
                style={{ width: '65vw', height: '90vh' }}
                footer={dialogFooter}
                onHide={onHide}
                modal
            >
                <form className='treatmentplan_edit_form' onSubmit={handleSubmit(onSubmit)} autoComplete={'off'}>

                    <div className={`row`}>
                        {getFields()}
                    </div>

                </form>
            </Dialog>
        </>);
};
export default TreatmentPlanDialog;
import PropTypes from "prop-types";
import "../../scss/component/wavemicrophone.scss";

/**@DefaultProps */
const defaultProps = {
  isListening: false,
  setIsListening: () => {}
}

/**@PropTypes */
const WaveMicButtonPropTypes = {
  isListening: PropTypes.bool,
  setIsListening: PropTypes.func
}


/**@Component */
const WaveMicButton = ( { isListening, setIsListening }) => {

  const toggleListening = () => {
    setIsListening(prev => !prev);
  };

  return (
    <div className="wave-mic-container">
        <button className="mic-button" onClick={toggleListening}>
          <i className="pi pi-microphone"></i>
        </button>

      {isListening && (
        <div className="wave-animation">
          {[...Array(2)].map((_, index) => (
            <div key={index} className={`wave wave-${index + 1}`} />
          ))}
        </div>
      )}
    </div>
  );
};

WaveMicButton.propTypes = WaveMicButtonPropTypes;
WaveMicButton.defaultProps = defaultProps;

export default WaveMicButton;

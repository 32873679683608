import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from "lodash";
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';

import config from '../../../config/config';
import configImages from '../../../config/configImages';
//session expiry modal
import SessionExpiryModal from '../../Cruds/CommonModals/SessionexpiryModal'
import fetchMethodRequest from '../../../config/service';
import VoiceProcess from '../../../common/Voice/VoiceProcess';
import WaveMicrophone from '../../../common/Voice/WaveMicrophone';
import { getVoiceCommands } from '../../../utils/voiceCommands';
import EventEmitter from '../../../events/EventEmitter';

const Topbar  = (props) => {
  const propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };

  const [settingsInfo, setSettingsInfo] = useState({});
  const [sessionExpiryModal, setsessionExpiryModal] = useState(false);
  const [hospitalData,setHospitalData] = useState({});
  const [listening, setListening] = useState(false);
  const [tryagain, setTryagain] = useState(false);
  const [transcript, setTranscript] = useState("");
  const [commands, setCommands] = useState([])

  const voiceProcessRef = useRef(null);
  const timerRef = useRef(null);

  const navigate = useNavigate();
 
  useEffect(() => {
    let sessionexpired = localStorage.getItem('sessionexpired');
    if(localStorage.HospitalData){
      setHospitalData(JSON.parse(localStorage.getItem('HospitalData')));
    }
    
    if (sessionexpired == "true") {      
      setsessionExpiryModal(true);
    }
    getVoiceCommands(navigate, closeModal, retry).then(commands => setCommands(commands));
    return () => {      
    };
  }, []);

  useEffect(() => {
    startTimer();
    return () => clearTimer();
  },[transcript])


  const startTimer = () => {
    clearTimer(); // Clear any existing timer
    timerRef.current = setTimeout(() => {
      retry();
    }, 10000); 
  }

  const clearTimer = () => {
    setTryagain(false);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  }

  const setListen = (state) => {
    if(state) startTimer();
    setListening(state);
  }

  function closeModal(type){
    voiceProcessRef?.current?.stopListening();
    voiceProcessRef?.current?.resetTranscript();
    if(type === "openForm") {
      setTimeout(() => {
        EventEmitter.emit("openFormModal");
      }, 1000);
    }
  }



  

  function retry() {
    voiceProcessRef?.current?.resetTranscript();
    // voiceProcessRef?.current?.stopListening();
    // setListening(true);
    setTryagain(true);
    setListen(false);
  }

  function startAndStopListening() {
    voiceProcessRef?.current?.startStopListening();

  }

 
    const { changeMobileSidebarVisibility, changeSidebarVisibility } = props;
    return (
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            <TopbarSidebarButton
              changeMobileSidebarVisibility={changeMobileSidebarVisibility}
              changeSidebarVisibility={changeSidebarVisibility}
            />
            <Link className='topbarLogo ml-3' to="/dashboard" >
              {config.displayProjectName ?
                <img src={configImages.loginImage} className='topbarProjectLogo'></img> : hospitalData.hospitalName ? hospitalData.hospitalName : config.appName}
            </Link>

          </div>
          <div className="topbar__right">
            <div className="d-flex align-items-center" onClick={startAndStopListening}>
              {
                listening ? 
                <i className="pi pi-stop-circle bg-danger rounded-pill p-2" style={{ fontSize: '1.2rem', color: "white" }}></i> :
                <i className="pi pi-microphone bg-warning rounded-pill p-2" style={{ fontSize: '1.2rem', color: "white" }}></i> 

              }
            </div>
            <TopbarProfile />
            <VoiceProcess ref={voiceProcessRef} setListen={setListen} modalWidth={"25vw"} modalPosition={"center"} modalHeight={"50vh"} commands={commands} setTranscript={setTranscript} showLoader={false}>
              {/* <h1>Vamsi Krishna</h1> */}
              <div className="d-flex flex-column px-4 py-3">
                {/**@CrossButton */}
                <div className="ms-auto" onClick={closeModal}><i className="pi pi-times rounded-pill bg-danger p-1" style={{fontSize: "1rem", color: "white"}}></i></div>
                {/**@Text */}
                <div style={{height: "30vh"}} className="p-4">
                  <span>{ transcript && transcript !== "" ? transcript :  tryagain ? "Didn't hear that. Try again.  " : "Listening..."}</span>
                </div>
                {/**@AnimationMicrophone */}
                <div>
                  <WaveMicrophone isListening={listening} setIsListening={setListen}/>
                </div>
              </div>
            </VoiceProcess>
          </div>
          {sessionExpiryModal ?
            <SessionExpiryModal
              SOpen={sessionExpiryModal}
            />
            : null}
        </div>
      </div>
    );
  }


export default Topbar;

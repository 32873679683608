import BeatLoader from "react-spinners/BeatLoader";
import PropTypes from "prop-types";

/**
 * 
 * @isLoading - { Boolean} 
 * @returns 
 */


/**@DefaultProps */
const defaultProps = {
  isLoading: false,
}

/**@PropTypes */
const BLoaderProps = {
  isLoading: PropTypes.bool.isRequired,
}


/**@Component */
const BLoader = ({ isLoading }) => {
  return (
    <div className="position-absolute top-50 start-50 translate-middle">
        <BeatLoader 
            color={"red"}
            loading={isLoading}
            size={20}
            aria-label="Loading Spinner"
            data-testid="loader"
        />
    </div>
  )
}

BLoader.propTypes = BLoaderProps
BLoader.defaultProps = defaultProps;


export default BLoader;
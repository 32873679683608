import React, { useEffect, useState, useRef } from "react";
import { InputText } from 'primereact/inputtext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { allCountries } from 'country-telephone-data';
import { Controller } from 'react-hook-form'; // Import Controller
import '../../../scss/component/phonenumber.scss';
import { Tooltip } from 'primereact/tooltip'; // Import Tooltip

const DoPatientPhoneField = (props) => {
  const id = props.id ? props.id : props.name;
  const label = props.label ? props.label : props.name;
  const name = props.name;
  const type = props.type ? props.type : 'text';
  let field = props.field;
  const placeholder = props.placeholder;
  const style = props.style ? props.style : null;
  const item = props.item ? props.item : {};
  const validateName = item.customValidateName ? item.customValidateName : props.name;

  const [selectedCountry, setSelectedCountry] = useState(() => {
    const initialCountry = allCountries.find(c => c.iso2 === 'us'); // Set default country to US or based on initial value
    return initialCountry ? initialCountry.iso2 : 'us';
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [prefix, setPrefix] = useState('');
  const { control, errors, setValue, markReq } = props;

  const tooltipTexts = "Enable Texts.";
  const tooltipCalls = "Enable Calls.";
  
  useEffect(() => {
    setValue("countryName", selectedCountry);
  }, [selectedCountry]);

  let markRequired = () => {
    return (
      <FontAwesomeIcon
        color='red'
        icon={faAsterisk}
        style={{ width: '7px', marginBottom: "5px", marginLeft: "2px" }}
      />
    );
  }; 

  const countryOptions = React.useMemo(() => allCountries.map((country) => ({
    label: `${country.name} (+${country.dialCode})`,
    value: country.iso2,
    icon: `https://flagcdn.com/w20/${country.iso2.toLowerCase()}.png`,
    code: `+${country.dialCode}`
  })), []);

  const handleCountryChange = (option) => {
    const countryValue = option ? option.value : selectedCountry;
    setSelectedCountry(countryValue);
  };

  const handlePhoneNumberChange = (e) => {
      setValue(props.name,  e.target.value); 
      setPhoneNumber(e.target.value); 
  };



  const countryOptionTemplate = (option) => (
    <div className="flex align-items-center">
      <img alt={option?.label} src={`https://flagcdn.com/w40/${option?.value.toLowerCase()}.png`}
        onError={(e) => e.target.src = 'https://flagcdn.com/w40/xx.png'}
        style={{ marginRight: '10px', verticalAlign: 'middle', height: '40px', width: '40px' }} />
      {option?.label}
    </div>
  );



  const selectedCountryTemplate = (option) => {
    if (option) {
      return (
        <div className="d-flex align-items-center h-100 w-100">
          <div className="d-flex align-items-center gap-2">
            <img alt={option?.label} src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`}
              onError={(e) => e.target.src = 'https://flagcdn.com/w20/xx.png'} className="flagIcon"/>
            <div>{`+${allCountries.find(c => c.iso2 === option.value)?.dialCode}`}</div>
          </div>
        </div>
      );
    }
    return "Select a country";
  };

  const renderCountryCodePhone = () => (
    <div className="flex phone-field-component">
      {/* Country dropdown */}
      <div className="phone-input flex" style={{ width: props.item?.enableCalls ? "70%" : "100%" }}>
        <div className="country-code-dropdown" style={{ flex: 1, maxWidth: props.item?.enableCalls ? "44%" : "32%" }}>
          <Controller
            name="countryName"
            control={props.control}
            render={({ field }) => (
              <Dropdown
                value={props.getValues("countryName") ? props.getValues("countryName") : selectedCountry}
                options={countryOptions}
                onChange={(e) => {
                  field.onChange(e.value);
                  handleCountryChange(e);
                }}
                valueTemplate={selectedCountryTemplate}
                itemTemplate={countryOptionTemplate}
                optionLabel="label"
                filter
                showClear={false}
                filterBy="label,value"
                placeholder="Select a country"
                className="p-dropdown"
              />
            )}
          />
        </div>
        <div className="phone-number-input" style={{ flex: 3 ,maxWidth: props.item?.enableCalls ? "56%" : "68%" }}>
          <Controller
            name={props.name}
            control={props.control}
            render={({ field }) => (
              <InputText
                type="number"
                value={props.getValues(props.name) ? props.getValues(props.name) : phoneNumber}
                onChange={(e) => {
                  if (!item.maxLength || item.maxLength >= e?.target?.value?.length) {
                  field.onChange(e); // Update form state
                  handlePhoneNumberChange(e); // Update local state and log
                  }
                }}            
                placeholder="Enter phone number"
                className="p-inputtext"
              />
            )}
          />

        </div>
      </div>



      {/* Enable Texts switch */}
      <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: '5px', width: props.item?.enableCalls ? "30%" : "0%" }}>
        {props.item && props.item.enableTexts && (
          <div style={{ width: "50%", display: "flex", alignItems: "center" }}>
            <Controller
              name="enableTexts"
              control={props.control}
              defaultValue={props.getValues("enableTexts") ? props.getValues("enableTexts") : false}
              render={({ field: { onChange, value } }) => (
                <>
                  <InputSwitch
                    id="enableTexts"
                    checked={value}
                    onChange={(e) => { onChange(e.value) }}
                  />
                  <Tooltip target="#enableTexts" content={tooltipTexts} />
                </>
              )}

            />

          </div>
        )}
        {/* Enable Calls switch */}
        {props.item && props.item.enableCalls && (

          <div style={{ width: "50%", display: "flex", alignItems: "center" }}>
            <Controller
              name="enableCalls"
              control={props.control}
              defaultValue={props.getValues("enableCalls") ? props.getValues("enableCalls") : false}
              render={({ field: { onChange, value } }) => (
                <>
                  <InputSwitch
                    id="enableCalls"
                    checked={value}
                    onChange={(e) => { onChange(e.value) }}
                  />
                  <Tooltip target="#enableCalls" content={tooltipCalls} />
                </>
              )}
            />
          </div>
        )}

      </div>
    </div>
  );


  const labelStyle = {
    fontWeight: 'normal', // Adjust as needed for your design
    fontSize: '14px', // Ensure this matches other labels
  };

  return (
    <div className="flex flex-column">
      <div className="flex align-center"> {/* Added a wrapper div with flex class */}
        {!item.disableLabel && <>
          <label htmlFor={id} className="text-capitalize">{label}</label>
          {props.markReq && props.markReq === true && markRequired()}
        </>}

      </div>
      {/* <InputText
        type={type}
        onKeyDown={(e) => {
          // Check if the type is 'number' before preventing the 'e' key
          if (type === 'number' && (e.key === 'e' || e.key === 'E')) {
            e.preventDefault();
          }
        }}
        value={field.value}
        id={id}
        name={name}
        placeholder={placeholder}
        aria-describedby="username-help"
        style={style}
        disabled={item.disabled}
        onChange={onChange} /> */}
      {renderCountryCodePhone()}
      <small className="text-danger ">{props?.fieldState?.invalid ? props?.errors[props?.name]?.message : ''}</small>

    </div>
  )

}

export default DoPatientPhoneField;